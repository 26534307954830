<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulación</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Postulaciones</li>
                  <li class="breadcrumb-item active">Asignar Vehículos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="title">Asignación de Vehículos</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row justify-content-md-center">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-header bg-frontera text-white">
                        <h3 class="card-title">
                          Detalle Solicitud - {{ det_solicitud.id }}
                        </h3>
                      </div>
                      <div class="card-body text-muted text-xs">
                        <div class="row">
                          <div
                            class="col-md-4"
                            v-if="det_solicitud.solicitud_interna"
                          >
                            <ul class="ml-4 mb-0 fa-ul">
                              <li>
                                <span class="fa-li">
                                  <i
                                    class="fas fa-lg fa-chalkboard-teacher"
                                  ></i>
                                </span>
                                <strong
                                  >Solicitud -
                                  {{
                                    det_solicitud.cs_solicitud_interna_id
                                  }}</strong
                                >
                                <div class="row">
                                  <div class="col-md-5">Tipo:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna
                                        ? det_solicitud.solicitud_interna
                                            .nTipoSolicitud
                                        : ""
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Clase:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna
                                        ? det_solicitud.solicitud_interna
                                            .clase_solicitud
                                        : ""
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Fecha:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna.fecha
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Bloque:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna.bloque
                                        .nombre
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Sitio:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna.sitio
                                        .nombre
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">
                                    Usuario Solicitante:
                                  </div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna
                                        ? det_solicitud.solicitud_interna.user
                                            .name
                                        : ""
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Correo Usuario:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna
                                        ? det_solicitud.solicitud_interna.user
                                            .email
                                        : ""
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Celular Usuario:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna
                                        ? det_solicitud.solicitud_interna.user
                                            .cel
                                        : ""
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">
                                    Presupuesto Verificado:
                                  </div>
                                  <div class="col-md-7 mb-1">
                                    <i
                                      class="fa"
                                      :class="
                                        det_solicitud.solicitud_interna
                                          .presupuesto_verificado
                                          ? 'fa-check text-success'
                                          : 'fa-times-circle text-danger'
                                      "
                                    ></i>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Estado:</div>
                                  <div class="col-md-7 mb-1">
                                    <span
                                      class="badge"
                                      :class="
                                        det_solicitud.solicitud_interna
                                          .estado == 1
                                          ? 'bg-warning'
                                          : det_solicitud.solicitud_interna
                                              .estado == 2
                                          ? 'bg-success'
                                          : det_solicitud.solicitud_interna
                                              .estado == 3
                                          ? 'bg-danger'
                                          : det_solicitud.solicitud_interna
                                              .estado == 4
                                          ? 'bg-lime'
                                          : 'bg-secondary'
                                      "
                                    >
                                      {{
                                        det_solicitud.solicitud_interna.nEstado
                                      }}
                                    </span>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Justificación:</div>
                                  <div class="col-md-7 mb-1">
                                    <small>{{
                                      det_solicitud.solicitud_interna
                                        .justificacion
                                    }}</small>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Observaciones:</div>
                                  <div class="col-md-7 mb-3">
                                    <small>{{
                                      det_solicitud.solicitud_interna
                                        .observaciones
                                    }}</small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div
                            class="col-md-4"
                            v-if="
                              det_solicitud.solicitud_interna &&
                                det_solicitud.solicitud_interna.rutas.length > 0
                            "
                          >
                            <ul class="ml-4 mb-0 fa-ul">
                              <li>
                                <span class="fa-li">
                                  <i class="fas fa-road"></i>
                                </span>
                                <strong>Ruta Origen</strong>
                                <div class="row">
                                  <div class="col-md-5">Tipo Sitio:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong class="badge bg-success">{{
                                      det_solicitud.solicitud_interna.rutas[0]
                                        .tipo_sitio == 1
                                        ? det_solicitud.solicitud_interna
                                            .rutas[0].nombre
                                        : ""
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Sitio:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna.rutas[0]
                                        .nombre
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Dirección:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna.rutas[0]
                                        .direccion
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Nombre Contacto:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna.rutas[0]
                                        .nombre_contacto
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Número:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna.rutas[0]
                                        .telefono_contacto
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Correo:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna.rutas[0]
                                        .email_contacto
                                    }}</strong>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div
                            class="col-md-4"
                            v-if="
                              det_solicitud.solicitud_interna &&
                                det_solicitud.solicitud_interna.rutas.length > 1
                            "
                          >
                            <ul class="ml-4 mb-0 fa-ul">
                              <li>
                                <span class="fa-li">
                                  <i class="fas fa-road"></i>
                                </span>
                                <strong>Ruta Destino</strong>
                                <div class="row">
                                  <div class="col-md-5">Tipo Sitio:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong class="badge bg-primary">{{
                                      det_solicitud.solicitud_interna.rutas[1]
                                        .tipo_sitio == 2
                                        ? det_solicitud.solicitud_interna
                                            .rutas[1].nombre
                                        : ""
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Sitio:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna.rutas[1]
                                        .nombre
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Dirección:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna.rutas[1]
                                        .direccion
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Nombre Contacto:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna.rutas[1]
                                        .nombre_contacto
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Número:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna.rutas[1]
                                        .telefono_contacto
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Correo:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.solicitud_interna.rutas[1]
                                        .email_contacto
                                    }}</strong>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="col-md-4">
                            <ul class="ml-4 mb-0 fa-ul">
                              <li>
                                <span class="fa-li">
                                  <i class="fas fa-lg fa-info"></i>
                                </span>
                                <strong>Detalle</strong>
                                <div class="row" v-if="det_solicitud.empresa">
                                  <div class="col-md-5">Empresa:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.empresa.razon_social
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Tipo Servicio:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.nTipoServicio
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">
                                    {{
                                      det_solicitud.tipo_servicio == 1
                                        ? "Tipo Vehículo"
                                        : "Equipo"
                                    }}:
                                  </div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.tipo_servicio == 1
                                        ? det_solicitud.nTipoRemolque
                                          ? det_solicitud.nTipoVh +
                                            " - " +
                                            det_solicitud.nTipoRemolque
                                          : det_solicitud.nTipoVh
                                        : det_solicitud.nTipoEqui
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">
                                    Cantidad
                                    {{
                                      det_solicitud.tipo_servicio == 1
                                        ? "Vehículos"
                                        : "Equipos"
                                    }}:
                                  </div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.cantidad_vh_equi
                                    }}</strong>
                                  </div>
                                </div>
                                <div
                                  class="row"
                                  v-if="det_solicitud.radio_izaje"
                                >
                                  <div class="col-md-5">Radio Izaje:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.radio_izaje
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">
                                    Tiempo de Servicio:
                                  </div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.tiempo_serv
                                    }}</strong>
                                    <small>(días)</small>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">
                                    Turno:
                                  </div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{ det_solicitud.nTurno }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Estado:</div>
                                  <div class="col-md-7 mb-1">
                                    <span
                                      class="badge"
                                      :class="
                                        det_solicitud.estado == 1
                                          ? 'bg-warning'
                                          : det_solicitud.estado == 2
                                          ? 'bg-success'
                                          : 'bg-secondary'
                                      "
                                    >
                                      {{ det_solicitud.nEstado }}
                                    </span>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Observación:</div>
                                  <div class="col-md-7 mb-3">
                                    <small>{{
                                      det_solicitud.observaciones
                                    }}</small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="col-md-4">
                            <ul class="ml-4 mb-0 fa-ul">
                              <li>
                                <span class="fa-li">
                                  <i class="fas fa-lg fa-cogs"></i>
                                </span>
                                <strong
                                  >Material -
                                  {{
                                    det_solicitud.descripcion_material
                                  }}</strong
                                >
                                <div class="row">
                                  <div class="col-md-5">Cantidad:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      det_solicitud.cantidad_material
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Peso:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong
                                      >{{ det_solicitud.peso }}
                                      <small>(Kg)</small></strong
                                    >
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Largo:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{ det_solicitud.largo }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Alto:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{ det_solicitud.alto }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Ancho:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{ det_solicitud.ancho }}</strong>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Postulaciones -->
              <div class="card-body table_responsive pt-0">
                <h5>Postulaciones</h5>
                <div class="row">
                  <div
                    v-for="(det, indexDet) in det_solicitud.cantidad_vh_equi"
                    :key="det"
                    class="col-md-12"
                  >
                    <div
                      class="card card-outline card-teal collapsed-card mb-1"
                    >
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <small
                            ><b
                              >{{
                                det_solicitud.tipo_servicio == 1
                                  ? det_solicitud.nTipoRemolque
                                    ? det_solicitud.nTipoVh +
                                      " - " +
                                      det_solicitud.nTipoRemolque
                                    : det_solicitud.nTipoVh
                                  : det_solicitud.nTipoEqui
                              }}
                              - {{ det }}</b
                            ></small
                          >
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                            class="btn btn-tool"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div
                        class="card-body table-responsive p-0"
                        style="display: none"
                      >
                        <table
                          class="table table-bordered table-striped table-hover table-sm text-nowrap"
                        >
                          <thead>
                            <tr>
                              <th class="text-center">Día</th>
                              <!-- Campo Vehículo General -->
                              <th
                                class="text-center"
                                v-if="det_solicitud.tipo_servicio == 1"
                              >
                                {{ det_solicitud.nTipoVh }}
                                <input
                                  type="text"
                                  v-if="diasPost != cantPostulados[indexDet]"
                                  v-model="listasForms.equipo[indexDet]"
                                  :id="'slct_vehiculo_' + indexDet"
                                  class="form-control form-control-sm"
                                  :disabled="
                                    accion == 2 ||
                                      det_solicitud.estado != 1 ||
                                      hoy > det_solicitud.fecha_fin
                                  "
                                  @change="buscarVehiculos(indexDet, null)"
                                />
                              </th>
                              <!-- Campo Equipo General -->
                              <th
                                class="text-center"
                                v-if="det_solicitud.tipo_servicio == 2"
                              >
                                Equipo
                                <input
                                  type="text"
                                  v-if="diasPost != cantPostulados[indexDet]"
                                  v-model="listasForms.equipo[indexDet]"
                                  :id="'slct_equipo_' + indexDet"
                                  class="form-control form-control-sm"
                                  :disabled="
                                    accion == 2 ||
                                      det_solicitud.estado != 1 ||
                                      hoy > det_solicitud.fecha_fin
                                  "
                                  @change="buscarEquipos(indexDet, null)"
                                />
                                <div
                                  v-if="
                                    det_solicitud.tipo_servicio == 2 &&
                                      listasForms.equipo_placa[indexDet]
                                  "
                                >
                                  <small
                                    >Placa:
                                    <strong>{{
                                      listasForms.equipo_placa[indexDet]
                                    }}</strong></small
                                  >
                                </div>
                                <div
                                  v-if="
                                    det_solicitud.tipo_servicio == 2 &&
                                      listasForms.equipo_serial[indexDet]
                                  "
                                >
                                  <small
                                    >Serial:
                                    <strong>{{
                                      listasForms.equipo_serial[indexDet]
                                    }}</strong></small
                                  >
                                </div>
                              </th>
                              <th
                                class="text-center"
                                v-if="
                                  det_solicitud.tipo_servicio == 1 &&
                                    aplicaRemol
                                "
                              >
                                {{ det_solicitud.nTipoRemolque }}
                                <input
                                  type="text"
                                  v-if="diasPost != cantPostulados[indexDet]"
                                  v-model="listasForms.remolque[indexDet]"
                                  :id="'slct_remolque_' + indexDet"
                                  class="form-control form-control-sm"
                                  :disabled="
                                    accion == 2 ||
                                      det_solicitud.estado != 1 ||
                                      hoy > det_solicitud.fecha_fin ||
                                      !aplicaRemol
                                  "
                                  @change="buscarRemolques(indexDet, null)"
                                />
                              </th>
                              <!-- Campo Conductor / Operario General -->
                              <th class="text-center">
                                {{
                                  det_solicitud.tipo_servicio == 1
                                    ? "Conductor"
                                    : "Operario"
                                }}
                                <input
                                  type="text"
                                  v-if="diasPost != cantPostulados[indexDet]"
                                  v-model="listasForms.persona[indexDet]"
                                  :id="
                                    det_solicitud.tipo_servicio == 1
                                      ? 'slct_conductor_' + indexDet
                                      : 'slct_operario_' + indexDet
                                  "
                                  class="form-control form-control-sm"
                                  :disabled="
                                    accion == 2 ||
                                      det_solicitud.estado != 1 ||
                                      hoy > det_solicitud.fecha_fin
                                  "
                                  @change="
                                    det_solicitud.tipo_servicio == 1
                                      ? buscarConductores(indexDet, null)
                                      : buscarOperarios(indexDet, null)
                                  "
                                />
                                <small>{{
                                  listasForms.persona_name[indexDet]
                                }}</small>
                              </th>
                              <!--Campo Conductor 2 / Operario General 2  -->
                              <th
                                class="text-center"
                                v-if="
                                  det_solicitud.turno == 3 &&
                                    det_solicitud.tipo_servicio == 1
                                "
                              >
                                Conductor 2
                                <input
                                  type="text"
                                  v-if="diasPost != cantPostulados[indexDet]"
                                  v-model="listasForms.persona2[indexDet]"
                                  :id="'slct_conductor2_' + indexDet"
                                  class="form-control form-control-sm"
                                  :disabled="
                                    accion == 2 ||
                                      det_solicitud.estado != 1 ||
                                      hoy > det_solicitud.fecha_fin
                                  "
                                  @change="buscarConductores2(indexDet, null)"
                                />
                                <small>{{
                                  listasForms.persona_name2[indexDet]
                                }}</small>
                              </th>
                              <!-- Campo Aparejador General -->
                              <th
                                class="text-center"
                                v-if="det_solicitud.tipo_servicio == 2"
                              >
                                Aparejador
                                <input
                                  type="text"
                                  v-if="diasPost != cantPostulados[indexDet]"
                                  v-model="listasForms.aparejador[indexDet]"
                                  :id="'slct_aparejador_' + indexDet"
                                  class="form-control form-control-sm"
                                  :disabled="
                                    accion == 2 ||
                                      det_solicitud.estado != 1 ||
                                      hoy > det_solicitud.fecha_fin
                                  "
                                  @change="buscarAparejadores(indexDet, null)"
                                />
                                <small>{{
                                  listasForms.aparejador_name[indexDet]
                                }}</small>
                              </th>
                              <!-- Campo Estado General -->
                              <th class="text-center">Estado</th>
                              <!-- Campo Acciones General -->
                              <th class="text-center">
                                Acción
                                <div>
                                  <button
                                    class="btn btn-default btn-sm bg-frontera-top-left text-white"
                                    v-if="diasPost != cantPostulados[indexDet]"
                                    :disabled="
                                      accion == 2 ||
                                        det_solicitud.estado != 1 ||
                                        hoy > det_solicitud.fecha_fin
                                    "
                                    @click="save(indexDet, null)"
                                  >
                                    <i class="fas fa-save"></i>
                                  </button>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="form1">
                            <tr
                              v-for="(dias, indexDias) in diasPost"
                              :key="dias"
                            >
                              <td class="text-center">{{ dias }}</td>
                              <!-- Campo Vehículo Día -->
                              <td v-if="det_solicitud.tipo_servicio == 1">
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="form1[indexDet][indexDias].equipo"
                                  :id="
                                    'slct_vehiculo_' +
                                      indexDet +
                                      '_' +
                                      indexDias
                                  "
                                  :disabled="
                                    accion == 2 ||
                                      selectVehi != `${indexDet}_${indexDias}`
                                  "
                                  @change="buscarVehiculos(indexDet, indexDias)"
                                />
                              </td>
                              <!-- Campo Equipo Día -->
                              <td v-if="det_solicitud.tipo_servicio == 2">
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="form1[indexDet][indexDias].equipo"
                                  :id="
                                    'slct_equipo_' + indexDet + '_' + indexDias
                                  "
                                  :disabled="
                                    accion == 2 ||
                                      selectVehi != `${indexDet}_${indexDias}`
                                  "
                                  @change="buscarEquipos(indexDet, indexDias)"
                                />
                                <div
                                  v-if="
                                    form1[indexDet][indexDias].equipo_type ==
                                      'App\\Equipo' &&
                                      form1[indexDet][indexDias].equipo_placa
                                  "
                                >
                                  <small
                                    >Placa:
                                    <strong>{{
                                      form1[indexDet][indexDias].equipo_placa
                                    }}</strong></small
                                  >
                                </div>
                                <div
                                  v-if="
                                    form1[indexDet][indexDias].equipo_type ==
                                      'App\\Equipo' &&
                                      form1[indexDet][indexDias].equipo_serial
                                  "
                                >
                                  <small
                                    >Serial:
                                    <strong>{{
                                      form1[indexDet][indexDias].equipo_serial
                                    }}</strong></small
                                  >
                                </div>
                              </td>
                              <!-- Campo Remolque Día -->
                              <td
                                v-if="
                                  det_solicitud.tipo_servicio == 1 &&
                                    aplicaRemol
                                "
                              >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="form1[indexDet][indexDias].remolque"
                                  :id="
                                    'slct_remolque_' +
                                      indexDet +
                                      '_' +
                                      indexDias
                                  "
                                  :disabled="
                                    accion == 2 ||
                                      selectVehi !=
                                        `${indexDet}_${indexDias}` ||
                                      !form1[indexDet][indexDias].aplicaRemol ||
                                      form1[indexDet][indexDias].equipo == null
                                  "
                                  @change="buscarRemolques(indexDet, indexDias)"
                                />
                              </td>
                              <!-- Campo Conductor / Operario Día -->
                              <td>
                                <input
                                  type="text"
                                  v-model="form1[indexDet][indexDias].persona"
                                  :id="
                                    det_solicitud.tipo_servicio == 1
                                      ? 'slct_conductor_' +
                                        indexDet +
                                        '_' +
                                        indexDias
                                      : 'slct_operario_' +
                                        indexDet +
                                        '_' +
                                        indexDias
                                  "
                                  class="form-control form-control-sm"
                                  :disabled="
                                    det_solicitud.tipo_remolque != null
                                      ? det_solicitud.tipo_servicio == 1
                                        ? accion == 2 ||
                                          selectVehi !=
                                            `${indexDet}_${indexDias}` ||
                                          selectVehi !=
                                            `${indexDet}_${indexDias}` ||
                                          form1[indexDet][indexDias].equipo ==
                                            null ||
                                          form1[indexDet][indexDias].remolque ==
                                            null
                                        : accion == 2 ||
                                          selectVehi !=
                                            `${indexDet}_${indexDias}` ||
                                          selectVehi !=
                                            `${indexDet}_${indexDias}` ||
                                          form1[indexDet][indexDias].equipo ==
                                            null
                                      : accion == 2 ||
                                        selectVehi !=
                                          `${indexDet}_${indexDias}` ||
                                        selectVehi !=
                                          `${indexDet}_${indexDias}` ||
                                        form1[indexDet][indexDias].equipo ==
                                          null
                                  "
                                  @change="
                                    det_solicitud.tipo_servicio == 1
                                      ? buscarConductores(indexDet, indexDias)
                                      : buscarOperarios(indexDet, indexDias)
                                  "
                                />
                                <small>{{
                                  form1[indexDet][indexDias].persona_name
                                }}</small>
                              </td>
                              <!-- Campo Conductor2 Día-->
                              <td
                                v-if="
                                  det_solicitud.turno == 3 &&
                                    det_solicitud.tipo_servicio == 1
                                "
                              >
                                <input
                                  type="text"
                                  v-model="form1[indexDet][indexDias].persona2"
                                  :id="
                                    'slct_conductor_' +
                                      indexDet +
                                      '_' +
                                      indexDias
                                  "
                                  class="form-control form-control-sm"
                                  :disabled="
                                    accion == 2 ||
                                      selectVehi !=
                                        `${indexDet}_${indexDias}` ||
                                      selectVehi !=
                                        `${indexDet}_${indexDias}` ||
                                      form1[indexDet][indexDias].persona == null
                                  "
                                  @change="
                                    buscarConductores2(indexDet, indexDias)
                                  "
                                />
                                <small>{{
                                  form1[indexDet][indexDias].persona_name2
                                }}</small>
                              </td>
                              <!-- Campo Aparejador Día -->
                              <td v-if="det_solicitud.tipo_servicio == 2">
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="
                                    form1[indexDet][indexDias].aparejador
                                  "
                                  :id="
                                    'slct_aparejador_' +
                                      indexDet +
                                      '_' +
                                      indexDias
                                  "
                                  :disabled="
                                    accion == 2 ||
                                      selectVehi !=
                                        `${indexDet}_${indexDias}` ||
                                      selectVehi !=
                                        `${indexDet}_${indexDias}` ||
                                      form1[indexDet][indexDias].persona == null
                                  "
                                  @change="
                                    buscarAparejadores(indexDet, indexDias)
                                  "
                                />
                                <small>{{
                                  form1[indexDet][indexDias].aparejador_name
                                }}</small>
                              </td>
                              <!-- Campo Estado Día -->
                              <td class="text-center">
                                <span
                                  class="badge"
                                  v-if="form1[indexDet][indexDias].estado"
                                  :class="
                                    form1[indexDet][indexDias].estado == 1
                                      ? 'bg-lime'
                                      : form1[indexDet][indexDias].estado == 2
                                      ? 'bg-success'
                                      : form1[indexDet][indexDias].estado == 3
                                      ? 'bg-danger'
                                      : form1[indexDet][indexDias].estado == 4
                                      ? 'bg-secondary'
                                      : 'bg-ligth'
                                  "
                                >
                                  {{ form1[indexDet][indexDias].nEstado }}
                                </span>
                              </td>
                              <!-- Campo Acciones Día -->
                              <td class="text-center">
                                <div class="btn-group">
                                  <button
                                    class="btn btn-sm bg-navy"
                                    v-if="
                                      accion == 1 &&
                                        (form1[indexDet][indexDias].estado ==
                                          1 ||
                                          form1[indexDet][indexDias].estado ==
                                            null) &&
                                        $store.getters.can(
                                          'cs.internosPostulaciones.edit'
                                        ) &&
                                        (selectVehi !=
                                          `${indexDet}_${indexDias}` ||
                                          selectVehi == null)
                                    "
                                    @click="editDetPost(indexDet, indexDias)"
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                  <button
                                    class="btn btn-sm bg-primary"
                                    v-if="
                                      accion == 1 &&
                                        $store.getters.can(
                                          'cs.internosPostulaciones.edit'
                                        ) &&
                                        selectVehi == `${indexDet}_${indexDias}`
                                    "
                                    @click="save(indexDet, indexDias)"
                                  >
                                    <i class="fas fa-save"></i>
                                  </button>
                                  <button
                                    class="btn btn-sm bg-danger"
                                    v-if="
                                      accion == 1 &&
                                        det_solicitud.estado == 1 &&
                                        hoy <=
                                          form1[indexDet][indexDias].fecha &&
                                        form1[indexDet][indexDias].id &&
                                        $store.getters.can(
                                          'cs.internosPostulaciones.delete'
                                        ) &&
                                        selectVehi != `${indexDet}_${indexDias}`
                                    "
                                    @click="destroy(indexDet, indexDias)"
                                  >
                                    <i class="fas fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import moment from "moment";
import "moment/locale/es";

export default {
  name: "PostulacionForm",
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      accion: 2,
      det_solicitud: [],
      diasPost: null,
      cantPostulados: [],
      hoy: moment().format("YYYY-MM-DD"),
      form1: [],
      selectVehi: null,
      aplicaRemol: false,
      listasForms: {
        equipo: [],
        remolque: [],
        persona: [],
        persona2: [],
        persona_name: [],
        persona_name2: [],
        operario: [],
      },
    };
  },

  methods: {
    init() {
      this.cargando = true;
      this.aplicaRemol = false;
      this.selectVehi = null;
      this.cantPostulados = [];
      this.accion = this.$route.params.accion;
      this.cs_det_solicitud_interna_id = this.$route.params.det_solicitud.id;

      axios
        .get(
          "/api/cs/postulacionesInternas/show/" +
            this.cs_det_solicitud_interna_id
        )
        .then((response) => {
          this.det_solicitud = response.data;
          if (this.det_solicitud.tipo_servicio == 1) {
            //Es Transporte
            if (this.det_solicitud.tipo_remolque != null) {
              this.aplicaRemol = true;
            }
          }

          // Se determinan los dias de acuerdo con las fechas del detalle de la solicitud
          this.diasPost = this.det_solicitud.tiempo_serv;
          this.buildArrayForm();
          this.cargando = false;
        })
        .catch(function(error) {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buildArrayForm() {
      let arrayPost = [];
      this.listasForms = {
        equipo: [],
        equipo_placa: [],
        equipo_serial: [],
        remolque: [],
        persona: [],
        persona2: [],
        persona_name: [],
        persona_name2: [],
        operario: [],
        aparejador: [],
        aparejador_name: [],
      };

      for (
        let indiceDet = 1;
        indiceDet <= this.det_solicitud.cantidad_vh_equi;
        indiceDet++
      ) {
        let arrayDias = [];
        let cant_postulados = 0;
        for (let indiceDias = 1; indiceDias <= this.diasPost; indiceDias++) {
          let form = {
            id: null,
            equipo_type:
              this.det_solicitud.tipo_servicio == 1
                ? "App\\Vehiculo"
                : "App\\Equipo",
            equipo_id: null,
            equipo: null,
            equipo_placa: null,
            equipo_serial: null,
            cs_det_solicitud_interna: this.det_solicitud.id,
            persona_type:
              this.det_solicitud.tipo_servicio == 1
                ? "App\\Conductor"
                : "App\\Operario",
            persona_id: null,
            persona: null,
            persona_name: null,
            persona_2_id: null,
            persona2: null,
            persona_name2: null,
            remolque_id: null,
            remolque: null,
            aplicaRemol: this.aplicaRemol,
            aparejador_id: null,
            aparejador: null,
            aparejador_name: null,
            fecha: moment(this.det_solicitud.solicitud_interna.fecha)
              .add(indiceDias - 1, "days")
              .format("YYYY-MM-DD"),
            equipo_indice: indiceDet,
            pos_dia: indiceDias,
            estado: null,
          };

          let post = this.det_solicitud.postulaciones.find(
            (post) =>
              post.equipo_indice == indiceDet && post.pos_dia == indiceDias
          );

          if (post) {
            cant_postulados++;
            let equipoInfo = post.equipo
              ? post.equipo_type === "App\\Vehiculo"
                ? post.equipo.placa
                : `${post.equipo.placa} ${post.equipo.serial}`
              : "No hay información de equipo";
            form = {
              id: post.id,
              equipo_type: post.equipo_type,
              equipo_id: post.equipo_id ? post.equipo_id : null,
              equipo: equipoInfo,
              equipo_placa: post.equipo ? post.equipo.placa : null,
              equipo_serial: post.equipo ? post.equipo.serial : null,
              cs_det_solicitud_interna: this.det_solicitud.id,
              persona_type: post.persona_type,
              persona_id: post.persona_id,
              persona: post.persona ? post.persona.numero_documento : null,
              persona_name: post.persona
                ? `${post.persona.nombres} ${post.persona.apellidos}`
                : null,
              persona_2_id: post.persona_2_id,
              persona2: post.persona2 ? post.persona2.numero_documento : null,
              persona_name2: post.persona2
                ? `${post.persona2.nombres} ${post.persona2.apellidos}`
                : null,
              remolque_id: post.remolque_id,
              remolque: post.remolque ? post.remolque.placa : null,
              aplicaRemol: this.aplicaRemol,
              aparejador_id: post.aparejador_id,
              aparejador: post.aparejador
                ? post.aparejador.numero_documento
                : null,
              aparejador_name: post.aparejador
                ? `${post.aparejador.nombres} ${post.aparejador.apellidos}`
                : null,
              fecha: post.fecha,
              equipo_indice: post.equipo_indice,
              pos_dia: post.pos_dia,
              estado: post.estado,
              nEstado: post.nEstado,
            };
          }
          arrayDias.push(form);
        }
        this.cantPostulados.push(cant_postulados);
        arrayPost.push(arrayDias);
      }
      this.form1 = arrayPost;
    },

    /***** Funciones para los campos de los formularios */
    async buscarVehiculos(indexDet, indexDias) {
      this.cargando = true;
      let dato = null;
      let veh = null;
      let fecha = null;
      let fecha_ini = null;
      let fecha_fin = null;
      if (indexDias != null) {
        dato = this.form1[indexDet][indexDias].equipo;
        fecha = this.form1[indexDet][indexDias].fecha;
      } else {
        this.selectVehi = null;
        dato = this.listasForms.equipo[indexDet];
        fecha_ini = this.det_solicitud.solicitud_interna.fecha;
        fecha_fin = moment(this.det_solicitud.solicitud_interna.fecha)
          .add(this.det_solicitud.tiempo_serv, "days")
          .format("YYYY-MM-DD");
      }

      if (dato) {
        let res = await this.validarStatus(
          dato,
          "V",
          fecha,
          fecha_ini,
          fecha_fin
        );
        if (res.data.length > 0) {
          veh = res["data"][0];
          //Valida el tipo de vehiculo
          if (
            res["data"][0].tipo_vehiculo_id !=
            this.det_solicitud.tipo_vehiculo.id
          ) {
            if (indexDias != null) {
              this.form1[indexDet][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexDet] = null;
            }
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El tipo de vehículo no coincide.`,
              confirmButtonText: "Aceptar!",
            });
            this.selectAllRemolq(indexDet, indexDias, null);
            this.selectAllVehi(indexDet, indexDias, null);
            return false;
          }

          /* if (veh.ultimo_registro) {
            const fechaAct = moment().format("YYYY-MM-DD HH:mm:ss");
            //Se le resta 2 horas a la fecha actual
            const fechaCorte = moment(fechaAct)
              .add(-2, "hours")
              .format("YYYY-MM-DD HH:mm:ss");
            //Se obtiene la fecha de reporte del vehículo
            const fechaGps = moment(
              veh.ultimo_registro.fecha_hora_ultimo_reporte
            ).format("YYYY-MM-DD HH:mm:ss");

            if (fechaCorte > fechaGps) {
              this.cargando = false;
              await this.$swal({
                text:
                  "El vehículo no ha reportado en las 2 últimas horas, FRONTERA debe autorizar..",
                icon: "warning",
                confirmButtonText: "Aceptar!",
              });
            }
          } else {
            this.cargando = false;
            await this.$swal({
              title: "No es posible postular este vehículo!!",
              text: "El vehículo no tiene tramas satelitales a la fecha",
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexDet] = null;
              this.selectAllRemolq(indexDet, indexDias, null);
              this.selectAllVehi(indexDet, indexDias, null);
            }
            this.cargando = false;
            return false;
          } */

          // Valida la documentación del vehículo
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            if (
              res.validacion.error.msg ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.obligatorios !== undefined) ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.vencidos !== undefined) ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.no_verificados !== undefined)
            ) {
              if (indexDias != null) {
                this.form1[indexDet][indexDias].equipo = null;
              } else {
                this.listasForms.equipo[indexDet] = null;
                this.selectAllRemolq(indexDet, indexDias, null);
                this.selectAllVehi(indexDet, indexDias, null);
              }
            }
            return false;
          }

          //Valida la información del vehículo
          if (res["info"] == false) {
            this.cargando = false;
            await this.$swal({
              text:
                "Hacen falta campos por diligenciar en el formulario de creación de Vehículos..",
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexDet] = null;
            }
            this.selectAllRemolq(indexDet, indexDias, null);
            this.selectAllVehi(indexDet, indexDias, null);

            return false;
          }

          //Valida la línea de negocio del vehículo
          if (!res["has_lineas"]) {
            this.$swal({
              text: `El vehículo no se encuentra en la línea de negocio Carga Seca`,
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexDet] = null;
            }
            this.selectAllRemolq(indexDet, indexDias, null);
            this.selectAllVehi(indexDet, indexDias, null);
            this.cargando = false;
            return false;
          }

          //Valida la inspeccion del vehiculo
          if (res["inspeccion"] != null) {
            if (res.inspeccion.estado == 4) {
              this.cargando = false;
              await this.$swal({
                text: `La inspeccion del vehículo se encuentra en estado rechazado`,
                icon: "error",
                confirmButtonText: "Aceptar!",
              });

              if (indexDias != null) {
                this.form1[indexDet][indexDias].equipo = null;
              } else {
                this.listasForms.equipo[indexDet] = null;
              }
              this.selectAllRemolq(indexDet, indexDias, null);
              this.selectAllVehi(indexDet, indexDias, null);

              return false;
            } else if (res.inspeccion.estado == 5) {
              this.cargando = false;
              await this.$swal({
                text: `La inspeccion del vehículo se encuentra aprobada con novedad`,
                icon: "success",
                confirmButtonText: "Aceptar!",
              });
            } else if (res.inspeccion.estado == 2) {
              this.cargando = false;
              await this.$swal({
                text: `La inspeccion del vehículo se encuentra aprobada`,
                icon: "success",
                confirmButtonText: "Aceptar!",
              });
            } else if (res.inspeccion.estado == 3) {
              this.cargando = false;
              await this.$swal({
                text: `La inspeccion del vehículo se encuentra autorizada`,
                icon: "success",
                confirmButtonText: "Aceptar!",
              });
            }
          } else {
            this.cargando = false;
            await this.$swal({
              text: `El vehículo no cuenta con inspección vigente`,
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexDet] = null;
            }
            this.selectAllRemolq(indexDet, indexDias, null);
            this.selectAllVehi(indexDet, indexDias, null);
            return false;
          }
          //Valida las postulaciones del vehículo
          if (res.postulacion) {
            if (indexDias != null) {
              this.form1[indexDet][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexDet] = null;
            }
            this.cargando = false;
            await this.$swal({
              text: `El vehículo tiene postulaciones activas en otra solicitud.`,
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            this.selectAllRemolq(indexDet, indexDias, null);
            this.selectAllVehi(indexDet, indexDias, null);
            return false;
          }
        } else {
          this.listasForms.remolque[indexDet] = null;
          this.cargando = false;
          await this.$swal({
            text:
              "El vehículo digitado NO se encuentra registrado, pruebe con otra placa.",
            icon: "error",
            confirmButtonText: "Aceptar!",
          });
          if (indexDias != null) {
            this.form1[indexDet][indexDias].equipo = null;
          } else {
            this.listasForms.equipo[indexDet] = null;
            this.selectAllRemolq(indexDet, indexDias, null);
            this.selectAllVehi(indexDet, indexDias, null);
          }
          return false;
        }
        this.cargando = false;
        this.$swal({
          text:
            "El vehículo cumple con los requisitos para poder ser postulado...",
          icon: "success",
          confirmButtonText: "Aceptar!",
        });
      }
      this.selectAllRemolq(indexDet, indexDias, null);
      this.selectAllVehi(indexDet, indexDias, veh);
      this.cargando = false;
    },

    async selectAllVehi(indexDet, indexDias, vehiculo) {
      if (indexDias != null) {
        this.form1[indexDet][indexDias].equipo = vehiculo
          ? vehiculo.placa
          : null;
        this.form1[indexDet][indexDias].equipo_id = vehiculo
          ? vehiculo.id
          : null;
        this.form1[indexDet][indexDias].aplicaRemol = this.aplicaRemol;
      } else {
        // Se valida si el vehículo es un cabezote
        this.listasForms.remolque[indexDet] =
          vehiculo && vehiculo.tipo_vehiculo_id == 1
            ? this.listasForms.remolque[indexDet]
            : null;
        this.aplicaRemol;

        // Se asignan los valores al formulario
        for (let i = 0; i < this.diasPost; i++) {
          if (
            this.hoy <=
              moment(this.form1[indexDet][i].fecha).format("YYYY-MM-DD") &&
            !this.form1[indexDet][i].id
          ) {
            this.form1[indexDet][i].equipo = vehiculo ? vehiculo.placa : null;
            this.form1[indexDet][i].equipo_id = vehiculo ? vehiculo.id : null;
          }
        }
      }
    },

    async buscarEquipos(indexDet, indexDias) {
      this.cargando = true;
      this.aplicaRemol = false;
      let dato = null;
      let equi = null;
      let fecha = null;
      let fecha_ini = null;
      let fecha_fin = null;
      if (indexDias != null) {
        dato = this.form1[indexDet][indexDias].equipo;
        fecha = this.form1[indexDet][indexDias].fecha;
      } else {
        this.selectVehi = null;
        dato = this.listasForms.equipo[indexDet];
        fecha_ini = this.det_solicitud.fecha_ini;
        fecha_fin = this.det_solicitud.fecha_fin;
      }

      if (dato) {
        let res = await this.validarStatus(
          dato,
          "E",
          fecha,
          fecha_ini,
          fecha_fin
        );

        if (res.data.length > 0) {
          equi = res["data"][0];
          if (res["data"][0].tipo_equipo != this.det_solicitud.tipo_vh_equi) {
            if (indexDias != null) {
              this.form1[indexDet][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexDet] = null;
            }
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El tipo de equipo no coincide.`,
              confirmButtonText: "Aceptar!",
            });
            this.selectAllEqui(indexDet, indexDias, null);
            return false;
          }
          //Valida la documentación del equipo
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            if (
              res.validacion.error.msg ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.obligatorios !== undefined) ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.vencidos !== undefined) ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.no_verificados !== undefined)
            ) {
              if (indexDias != null) {
                this.form1[indexDet][indexDias].equipo = null;
              } else {
                this.listasForms.equipo[indexDet] = null;
                this.selectAllEqui(indexDet, indexDias, null);
              }
            }
            return false;
          }

          //Valida la información del equipo
          if (res["info"] == false) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `Hacen falta campos por diligenciar en el formulario de creación de Equipos`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexDet] = null;
            }
            this.selectAllEqui(indexDet, indexDias, null);
            return false;
          }

          //Valida la línea de negocio del equipo
          if (!res["has_lineas"]) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El equipo no se encuentra en la línea de negocio Carga Seca`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexDet] = null;
            }
            this.selectAllEqui(indexDet, indexDias, null);
            return false;
          }

          //Valida las postulaciones del equipo
          if (res.postulacion) {
            if (indexDias != null) {
              this.form1[indexDet][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexDet] = null;
            }
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El equipo tiene postulaciones activas en otra solicitud.`,
              confirmButtonText: "Aceptar!",
            });
            this.selectAllEqui(indexDet, indexDias, null);
            return false;
          }
        } else {
          this.cargando = false;
          await this.$swal({
            icon: "error",
            text:
              "El equipo digitado NO se encuentra registrado, pruebe con otra placa o serial.",
            confirmButtonText: "Aceptar!",
          });
          if (indexDias != null) {
            this.form1[indexDet][indexDias].equipo = null;
          } else {
            this.listasForms.equipo[indexDet] = null;
            this.selectAllEqui(indexDet, indexDias, null);
          }
          return false;
        }
        this.cargando = false;
        await this.$swal({
          icon: "success",
          text:
            "El equipo cumple con los requisitos para poder ser postulado...",
          confirmButtonText: "Aceptar!",
        });
      }
      this.selectAllEqui(indexDet, indexDias, equi);
      this.cargando = false;
    },

    async selectAllEqui(indexDet, indexDias, equipo) {
      if (indexDias != null) {
        this.form1[indexDet][indexDias].equipo = equipo
          ? `${equipo.placa} ${equipo.serial}`
          : null;
        this.form1[indexDet][indexDias].equipo_placa = equipo
          ? equipo.placa
          : null;
        this.form1[indexDet][indexDias].equipo_serial = equipo
          ? equipo.serial
          : null;
        this.form1[indexDet][indexDias].equipo_id = equipo ? equipo.id : null;
      } else {
        this.listasForms.equipo[indexDet] = equipo
          ? `${equipo.placa} ${equipo.serial}`
          : null;
        this.listasForms.equipo_placa[indexDet] = equipo ? equipo.placa : null;
        this.listasForms.equipo_serial[indexDet] = equipo
          ? equipo.serial
          : null;

        // Se asignan los valores al formulario
        for (let i = 0; i < this.diasPost; i++) {
          if (
            this.hoy <=
              moment(this.form1[indexDet][i].fecha).format("YYYY-MM-DD") &&
            !this.form1[indexDet][i].id
          ) {
            this.form1[indexDet][i].equipo = equipo
              ? `${equipo.placa} ${equipo.serial}`
              : null;
            this.form1[indexDet][i].equipo_placa = equipo ? equipo.placa : null;
            this.form1[indexDet][i].equipo_serial = equipo
              ? equipo.serial
              : null;
            this.form1[indexDet][i].equipo_id = equipo ? equipo.id : null;
          }
        }
      }
    },

    async buscarRemolques(indexDet, indexDias) {
      this.cargando = true;
      let dato = null;
      let rem = null;
      let fecha = null;
      let fecha_ini = null;
      let fecha_fin = null;
      if (indexDias != null) {
        dato = this.form1[indexDet][indexDias].remolque;
        fecha = this.form1[indexDet][indexDias].fecha;
      } else {
        this.selectVehi = null;
        dato = this.listasForms.remolque[indexDet];
        fecha_ini = this.det_solicitud.solicitud_interna.fecha;
        fecha_fin = moment(this.det_solicitud.solicitud_interna.fecha)
          .add(this.det_solicitud.cantidad_vh_equi, "days")
          .format("YYYY-MM-DD");
      }

      if (dato) {
        let res = await this.validarStatus(
          dato,
          "R",
          fecha,
          fecha_ini,
          fecha_fin
        );

        if (res.data.length > 0) {
          rem = res["data"][0];

          //Valida la documentación del remolque
          if (res.validacion.error) {
            this.cargando = false;
            if (
              res.validacion.error.msg ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.obligatorios !== undefined) ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.vencidos !== undefined) ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.no_verificados !== undefined)
            ) {
              if (indexDias != null) {
                this.form1[indexDet][indexDias].remolque = null;
              } else {
                this.listasForms.remolque[indexDet] = null;
                this.selectAllRemolq(indexDet, indexDias, null);
              }
            }
            await this.msgDocumentacion(res);
            return false;
          }

          //Valida la información del remolque
          if (res["info"] == false) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `Hacen falta campos por diligenciar en el formulario de creación de Remolques`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].remolque = null;
            } else {
              this.listasForms.remolque[indexDet] = null;
              this.selectAllRemolq(indexDet, indexDias, null);
            }
            return false;
          }

          //Valida la línea de negocio del remolque
          if (!res["has_lineas"]) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El remolque no se encuentra en la línea de negocio Carga Seca`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].remolque = null;
            } else {
              this.listasForms.remolque[indexDet] = null;
              this.selectAllRemolq(indexDet, indexDias, null);
            }
            return false;
          }

          //Valida las postulaciones del remolque
          if (res.postulacion) {
            if (indexDias != null) {
              this.form1[indexDet][indexDias].remolque = null;
            } else {
              this.listasForms.remolque[indexDet] = null;
              this.selectAllRemolq(indexDet, indexDias, null);
            }
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El remolque tiene postulaciones activas en otra solicitud.`,
              confirmButtonText: "Aceptar!",
            });
            return false;
          }
        } else {
          this.listasForms.remolque[indexDet] = null;
          this.cargando = false;
          this.$swal({
            icon: "error",
            text:
              "El remolque digitado NO se encuentra registrado, pruebe con otra placa.",
            confirmButtonText: "Aceptar!",
          });
          if (indexDias != null) {
            this.form1[indexDet][indexDias].remolque = null;
          } else {
            this.listasForms.remolque[indexDet] = null;
            this.selectAllRemolq(indexDet, indexDias, null);
          }
          return false;
        }
        this.cargando = false;
        await this.$swal({
          icon: "success",
          text:
            "El remolque cumple con los requisitos para poder ser postulado...",
          confirmButtonText: "Aceptar!",
        });
      }
      this.selectAllRemolq(indexDet, indexDias, rem);
      this.cargando = false;
    },

    selectAllRemolq(indexDet, indexDias, remolque) {
      if (indexDias != null) {
        this.form1[indexDet][indexDias].remolque = remolque
          ? remolque.placa
          : null;
        this.form1[indexDet][indexDias].remolque_id = remolque
          ? remolque.id
          : null;
      } else {
        // Se asignan los valores al formulario
        for (let i = 0; i < this.diasPost; i++) {
          if (
            this.hoy <=
              moment(this.form1[indexDet][i].fecha).format("YYYY-MM-DD") &&
            !this.form1[indexDet][i].id
          ) {
            this.form1[indexDet][i].remolque = remolque ? remolque.placa : null;
            this.form1[indexDet][i].remolque_id = remolque ? remolque.id : null;
          }
        }
      }
    },

    async buscarConductores(indexDet, indexDias) {
      this.cargando = true;
      let dato = null;
      let cond = null;
      let fecha = null;
      let fecha_ini = null;
      let fecha_fin = null;
      if (indexDias != null) {
        dato = this.form1[indexDet][indexDias].persona;
        fecha = this.form1[indexDet][indexDias].fecha;
      } else {
        this.selectVehi = null;
        dato = this.listasForms.persona[indexDet];
        fecha_ini = this.det_solicitud.solicitud_interna.fecha;
        fecha_fin = moment(this.det_solicitud.solicitud_interna.fecha)
          .add(this.det_solicitud.cantidad_vh_equi, "days")
          .format("YYYY-MM-DD");
      }

      if (dato) {
        let res = await this.validarStatus(
          dato,
          "C",
          fecha,
          fecha_ini,
          fecha_fin
        );

        if (res.data.length > 0) {
          cond = res["data"][0];

          //Valida la documentación de la persona
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined
            ) {
              if (indexDias != null) {
                this.form1[indexDet][indexDias].persona = null;
              } else {
                this.listasForms.persona[indexDet] = null;
              }
              this.selectAllPersonas(indexDet, indexDias, null);
              return false;
            }
          }

          //Valida la información de la persona
          if (res["info"] == false) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `Hacen falta campos por diligenciar en el formulario de creación de Conductores`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].persona = null;
            } else {
              this.listasForms.persona[indexDet] = null;
            }
            this.selectAllPersonas(indexDet, indexDias, null);
            return false;
          }

          //Valida la línea de negocio de la persona
          if (!res["has_lineas"]) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El conductor no se encuentra en la línea de negocio Carga Seca`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].persona = null;
            } else {
              this.listasForms.persona[indexDet] = null;
            }
            this.selectAllPersonas(indexDet, indexDias, null);
            return false;
          }

          //Valida las postulaciones de la persona
          if (res.postulacion) {
            if (indexDias != null) {
              this.form1[indexDet][indexDias].persona = null;
            } else {
              this.listasForms.persona[indexDet] = null;
            }
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El conductor tiene postulaciones activas en otra solicitud.`,
              confirmButtonText: "Aceptar!",
            });
            this.selectAllPersonas(indexDet, indexDias, null);
            return false;
          }
        } else {
          this.listasForms.persona[indexDet] = null;
          this.cargando = false;
          this.$swal({
            icon: "error",
            text:
              "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
            confirmButtonText: "Aceptar!",
          });
          if (indexDias != null) {
            this.form1[indexDet][indexDias].persona = null;
          } else {
            this.listasForms.persona[indexDet] = null;
          }

          this.selectAllPersonas(indexDet, indexDias, null);
          return false;
        }
        this.cargando = false;
        await this.$swal({
          icon: "success",
          text:
            "El conductor cumple con los requisitos para poder ser postulado...",
          confirmButtonText: "Aceptar!",
        });
      }

      this.selectAllPersonas(indexDet, indexDias, cond);
      this.cargando = false;
    },

    async buscarConductores2(indexDet, indexDias) {
      this.cargando = true;
      let dato = null;
      let cond = null;
      let fecha = null;
      let fecha_ini = null;
      let fecha_fin = null;
      if (indexDias != null) {
        dato = this.form1[indexDet][indexDias].persona2;
        fecha = this.form1[indexDet][indexDias].fecha;
      } else {
        this.selectVehi = null;
        dato = this.listasForms.persona2[indexDet];
        fecha_ini = this.det_solicitud.solicitud_interna.fecha;
        fecha_fin = moment(this.det_solicitud.solicitud_interna.fecha)
          .add(this.det_solicitud.cantidad_vh_equi, "days")
          .format("YYYY-MM-DD");
      }

      if (dato) {
        let res = await this.validarStatus(
          dato,
          "C",
          fecha,
          fecha_ini,
          fecha_fin
        );

        if (res.data.length > 0) {
          cond = res["data"][0];

          //Valida la documentación de la persona
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined
            ) {
              if (indexDias != null) {
                this.form1[indexDet][indexDias].persona2 = null;
              } else {
                this.listasForms.persona2[indexDet] = null;
              }
              this.selectAllPersonas2(indexDet, indexDias, null);
              return false;
            }
          }

          //Valida la información de la persona
          if (res["info"] == false) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `Hacen falta campos por diligenciar en el formulario de creación de Conductores`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].persona2 = null;
            } else {
              this.listasForms.persona2[indexDet] = null;
            }
            this.selectAllPersonas2(indexDet, indexDias, null);
            return false;
          }

          //Valida la línea de negocio de la persona
          if (!res["has_lineas"]) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El conductor no se encuentra en la línea de negocio Carga Seca`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].persona2 = null;
            } else {
              this.listasForms.persona2[indexDet] = null;
            }
            this.selectAllPersonas2(indexDet, indexDias, null);
            return false;
          }

          //Valida las postulaciones de la persona
          if (res.postulacion) {
            if (indexDias != null) {
              this.form1[indexDet][indexDias].persona2 = null;
            } else {
              this.listasForms.persona2[indexDet] = null;
            }
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El conductor tiene postulaciones activas en otra solicitud.`,
              confirmButtonText: "Aceptar!",
            });
            this.selectAllPersonas2(indexDet, indexDias, null);
            return false;
          }
        } else {
          this.listasForms.persona2[indexDet] = null;
          this.cargando = false;
          this.$swal({
            icon: "error",
            text:
              "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
            confirmButtonText: "Aceptar!",
          });
          if (indexDias != null) {
            this.form1[indexDet][indexDias].persona2 = null;
          } else {
            this.listasForms.persona2[indexDet] = null;
          }

          this.selectAllPersonas2(indexDet, indexDias, null);
          return false;
        }
        this.cargando = false;
        await this.$swal({
          icon: "success",
          text:
            "El conductor cumple con los requisitos para poder ser postulado...",
          confirmButtonText: "Aceptar!",
        });
      }

      this.selectAllPersonas2(indexDet, indexDias, cond);
      this.cargando = false;
    },

    async buscarOperarios(indexDet, indexDias) {
      this.cargando = true;
      let dato = null;
      let oper = null;
      let fecha = null;
      let fecha_ini = null;
      let fecha_fin = null;
      if (indexDias != null) {
        dato = this.form1[indexDet][indexDias].persona;
        fecha = this.form1[indexDet][indexDias].fecha;
      } else {
        this.selectVehi = null;
        dato = this.listasForms.persona[indexDet];
        fecha_ini = this.det_solicitud.solicitud_interna.fecha;
        fecha_fin = moment(this.det_solicitud.solicitud_interna.fecha)
          .add(this.det_solicitud.cantidad_vh_equi, "days")
          .format("YYYY-MM-DD");
      }

      if (dato) {
        let res = await this.validarStatus(
          dato,
          "O",
          fecha,
          fecha_ini,
          fecha_fin,
          1
        );

        if (res.data.length > 0) {
          oper = res["data"][0];

          //Valida la documentación del operario
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined
            ) {
              if (indexDias != null) {
                this.form1[indexDet][indexDias].persona = null;
              } else {
                this.listasForms.persona[indexDet] = null;
              }
              this.selectAllPersonas(indexDet, indexDias, null);
              return false;
            }
          }

          // //Valida la información del operario
          // if (res["info"] == false) {
          //   this.$swal({
          //     icon: "error",
          //     text: `Hacen falta campos por diligenciar en el formulario de creación de Operarios`,
          //     toast: true,
          //     position: "top-end",
          //     showConfirmButton: false,
          //     timer: 3000,
          //     timerProgressBar: true,
          //   });
          //   if (indexDias != null) {
          //     this.form1[indexDet][indexDias].persona = null;
          //   } else {
          //     this.listasForms.persona[indexDet] = null;
          //   }
          //   this.selectAllPersonas(indexDet, indexDias, null);
          //   this.cargando = false;
          //   return false;
          // }

          //Valida la línea de negocio del operario
          if (!res["has_lineas"]) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El operario no se encuentra en la línea de negocio Carga Seca`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].persona = null;
            } else {
              this.listasForms.persona[indexDet] = null;
            }
            this.selectAllPersonas(indexDet, indexDias, null);
            return false;
          }

          //Valida las postulaciones del operario
          if (res.postulacion) {
            if (indexDias != null) {
              this.form1[indexDet][indexDias].persona = null;
            } else {
              this.listasForms.persona[indexDet] = null;
            }
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El operario tiene postulaciones activas en otra solicitud.`,
              confirmButtonText: "Aceptar!",
            });
            this.selectAllPersonas(indexDet, indexDias, null);
            return false;
          }
        } else {
          this.listasForms.persona[indexDet] = null;
          this.cargando = false;
          this.$swal({
            icon: "error",
            text:
              "El operario digitado NO se encuentra registrado, pruebe con otro documento.",
            confirmButtonText: "Aceptar!",
          });
          if (indexDias != null) {
            this.form1[indexDet][indexDias].persona = null;
          } else {
            this.listasForms.persona[indexDet] = null;
          }

          this.selectAllPersonas(indexDet, indexDias, null);
          return false;
        }

        this.cargando = false;
        await this.$swal({
          icon: "success",
          text:
            "El operario cumple con los requisitos para poder ser postulado...",
          confirmButtonText: "Aceptar!",
        });
      }
      this.cargando = false;
      this.selectAllPersonas(indexDet, indexDias, oper);
    },

    selectAllPersonas(indexDet, indexDias, persona) {
      if (indexDias != null) {
        this.form1[indexDet][indexDias].persona = persona
          ? persona.numero_documento
          : null;
        this.form1[indexDet][indexDias].persona_name = persona
          ? `${persona.nombres} ${persona.apellidos}`
          : null;
        this.form1[indexDet][indexDias].persona_id = persona
          ? persona.id
          : null;
      } else {
        // Se asignan los valores al formulario
        for (let i = 0; i < this.diasPost; i++) {
          if (
            this.hoy <=
              moment(this.form1[indexDet][i].fecha).format("YYYY-MM-DD") &&
            !this.form1[indexDet][i].id
          ) {
            this.listasForms.persona[indexDet] = persona
              ? persona.numero_documento
              : null;
            this.listasForms.persona_name[indexDet] = persona
              ? `${persona.nombres} ${persona.apellidos}`
              : null;
            this.form1[indexDet][i].persona = persona
              ? persona.numero_documento
              : null;
            this.form1[indexDet][i].persona_name = persona
              ? `${persona.nombres} ${persona.apellidos}`
              : null;
            this.form1[indexDet][i].persona_id = persona ? persona.id : null;
          }
        }
      }
    },

    selectAllPersonas2(indexDet, indexDias, persona) {
      if (indexDias != null) {
        this.form1[indexDet][indexDias].persona2 = persona
          ? persona.numero_documento
          : null;
        this.form1[indexDet][indexDias].persona_name2 = persona
          ? `${persona.nombres} ${persona.apellidos}`
          : null;
        this.form1[indexDet][indexDias].persona_2_id = persona
          ? persona.id
          : null;
      } else {
        // Se asignan los valores al formulario
        for (let i = 0; i < this.diasPost; i++) {
          if (
            this.hoy <=
              moment(this.form1[indexDet][i].fecha).format("YYYY-MM-DD") &&
            !this.form1[indexDet][i].id
          ) {
            this.listasForms.persona2[indexDet] = persona
              ? persona.numero_documento
              : null;
            this.listasForms.persona_name2[indexDet] = persona
              ? `${persona.nombres} ${persona.apellidos}`
              : null;
            this.form1[indexDet][i].persona2 = persona
              ? persona.numero_documento
              : null;
            this.form1[indexDet][i].persona_name2 = persona
              ? `${persona.nombres} ${persona.apellidos}`
              : null;
            this.form1[indexDet][i].persona_2_id = persona ? persona.id : null;
          }
        }
      }
    },

    async buscarAparejadores(indexDet, indexDias) {
      this.cargando = true;
      let dato = null;
      let apar = null;
      let fecha = null;
      let fecha_ini = null;
      let fecha_fin = null;
      if (indexDias != null) {
        dato = this.form1[indexDet][indexDias].aparejador;
        fecha = this.form1[indexDet][indexDias].fecha;
      } else {
        this.selectVehi = null;
        dato = this.listasForms.aparejador[indexDet];
        fecha_ini = this.det_solicitud.solicitud_interna.fecha;
        fecha_fin = moment(this.det_solicitud.solicitud_interna.fecha)
          .add(this.det_solicitud.cantidad_vh_equi, "days")
          .format("YYYY-MM-DD");
      }

      if (dato) {
        let res = await this.validarStatus(
          dato,
          "O",
          fecha,
          fecha_ini,
          fecha_fin,
          2
        );

        if (res.data.length > 0) {
          apar = res["data"][0];

          //Valida la documentación del aparejador
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined
            ) {
              if (indexDias != null) {
                this.form1[indexDet][indexDias].aparejador = null;
              } else {
                this.listasForms.aparejador[indexDet] = null;
              }
              this.selectAllApar(indexDet, indexDias, null);
              return false;
            }
          }

          // //Valida la información del aparejador
          // if (res["info"] == false) {
          //   this.$swal({
          //     icon: "error",
          //     text: `Hacen falta campos por diligenciar en el formulario de creación de Aparejadores`,
          //     toast: true,
          //     position: "top-end",
          //     showConfirmButton: false,
          //     timer: 3000,
          //     timerProgressBar: true,
          //   });
          //   if (indexDias != null) {
          //     this.form1[indexDet][indexDias].aparejador = null;
          //   } else {
          //     this.listasForms.aparejador[indexDet] = null;
          //   }
          //   this.selectAllApar(indexDet, indexDias, null);
          //   this.cargando = false;
          //   return false;
          // }

          //Valida la línea de negocio del aparejador
          if (!res["has_lineas"]) {
            await this.$swal({
              icon: "error",
              text: `El aparejador no se encuentra en la línea de negocio Carga Seca`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form1[indexDet][indexDias].aparejador = null;
            } else {
              this.listasForms.aparejador[indexDet] = null;
            }
            this.selectAllApar(indexDet, indexDias, null);
            this.cargando = false;
            return false;
          }

          //Valida las postulaciones del aparejador
          if (res.postulacion) {
            if (indexDias != null) {
              this.form1[indexDet][indexDias].aparejador = null;
            } else {
              this.listasForms.aparejador[indexDet] = null;
            }
            await this.$swal({
              icon: "error",
              text: `El aparejador tiene postulaciones activas en otra solicitud.`,
              confirmButtonText: "Aceptar!",
            });
            this.selectAllApar(indexDet, indexDias, null);
            this.cargando = false;
            return false;
          }
        } else {
          this.listasForms.aparejador[indexDet] = null;
          this.$swal({
            icon: "error",
            text:
              "El aparejador digitado NO se encuentra registrado, pruebe con otro documento.",
            confirmButtonText: "Aceptar!",
          });
          this.cargando = false;
          if (indexDias != null) {
            this.form1[indexDet][indexDias].aparejador = null;
          } else {
            this.listasForms.aparejador[indexDet] = null;
          }

          this.selectAllApar(indexDet, indexDias, null);
          return false;
        }

        this.$swal({
          icon: "success",
          text:
            "El aparejador cumple con los requisitos para poder ser postulado...",
          confirmButtonText: "Aceptar!",
        });
      }

      this.selectAllApar(indexDet, indexDias, apar);
      this.cargando = false;
    },

    selectAllApar(indexDet, indexDias, aparejador) {
      if (indexDias != null) {
        this.form1[indexDet][indexDias].aparejador = aparejador
          ? aparejador.numero_documento
          : null;
        this.form1[indexDet][indexDias].aparejador_name = aparejador
          ? `${aparejador.nombres} ${aparejador.apellidos}`
          : null;
        this.form1[indexDet][indexDias].aparejador_id = aparejador
          ? aparejador.id
          : null;
      } else {
        // Se asignan los valores al formulario
        for (let i = 0; i < this.diasPost; i++) {
          if (
            this.hoy <=
              moment(this.form1[indexDet][i].fecha).format("YYYY-MM-DD") &&
            !this.form1[indexDet][i].id
          ) {
            this.listasForms.aparejador[indexDet] = aparejador
              ? aparejador.numero_documento
              : null;
            this.listasForms.aparejador_name[indexDet] = aparejador
              ? `${aparejador.nombres} ${aparejador.apellidos}`
              : null;
            this.form1[indexDet][i].aparejador = aparejador
              ? aparejador.numero_documento
              : null;
            this.form1[indexDet][i].aparejador_name = aparejador
              ? `${aparejador.nombres} ${aparejador.apellidos}`
              : null;
            this.form1[indexDet][i].aparejador_id = aparejador
              ? aparejador.id
              : null;
          }
        }
      }
    },

    async validarStatus(
      dato,
      tipo,
      fecha = null,
      fecha_ini = null,
      fecha_fin = null,
      tipo_operario = null
    ) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "/api/cs/postulacionesInternas/listaConductores";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "/api/cs/postulacionesInternas/listaVehiculos";
      } else if (tipo == "R") {
        entidad = "Remolque";
        url = "/api/cs/postulacionesInternas/listaRemolques";
      } else if (tipo == "E") {
        entidad = "Equipo";
        url = "/api/cs/postulacionesInternas/listaEquipos";
      } else if (tipo == "O") {
        entidad = "Operario";
        url = "/api/cs/postulacionesInternas/listaOperarios";
      }

      let params = {
        cs_det_solicitud_interna: this.det_solicitud.id,
        entidad,
        dato,
        fecha: fecha,
        fecha_ini: fecha_ini,
        fecha_fin: fecha_fin,
        tipo_operario: tipo_operario,
      };

      let res = null;
      await axios
        .get(url, { params })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let title = "";
        let erroHtml = "<div>";
        if (res.validacion.error.msg) {
          title = res.validacion.error.msg;
        }
        if (res.validacion.error.documentos) {
          title = "Aviso en documentación";
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml += res.validacion.error.documentos.obligatorios.join(
              "<br>"
            );
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml += res.validacion.error.documentos.no_verificados.join(
              "<br>"
            );
          }
        }
        erroHtml += "</div>";
        await this.$swal({
          html: erroHtml,
          showCancelButton: false,
          focusConfirm: true,
          icon: "error",
          title,
          showConfirmButton: false,
          showCloseButton: true,
          footer: `
            <small style='text-align:center'>
              <b>Desde el 1 de octubre se aplicarán las restricciones</b>, 
              por favor tenga al día la documentación de sus vehículos, 
              para mas información comuniquese con la mesa de ayuda al siguiente número 
              <b>3176431376 Opción 3</b>
            </small>
          `,
        });
      }
    },
    /***** Fin - Funciones para los campos de los formularios */

    /***** Funciones para los botones de los formularios */
    editDetPost(indexDet, indexDias) {
      this.selectVehi = `${indexDet}_${indexDias}`;
    },

    async save(indexDet, indexDias) {
      const form = await this.validarForm(indexDet, indexDias);
      if (form.length > 0) {
        // Si hay formularios se guarda
        this.cargando = true;
        axios({
          method: "PUT",
          url: "/api/cs/postulacionesInternas",
          data: form,
        })
          .then((response) => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title: "Los datos de la postulación se guardaron exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            response.data.SolicitudEstado ? this.back() : this.init();
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        this.$swal({
          icon: "error",
          title: `Faltan datos necesarios para la postular el ${
            this.det_solicitud.tipo_servicio == 1 ? "Vehículo" : "Equipo"
          }.`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    validarForm(indexDet, indexDias) {
      let form = [];
      if (indexDias != null) {
        if (this.det_solicitud.tipo_servicio == 1) {
          // Es transporte
          if (
            (this.form1[indexDet][indexDias].aplicaRemol &&
              this.form1[indexDet][indexDias].equipo_id &&
              this.form1[indexDet][indexDias].persona_id &&
              this.form1[indexDet][indexDias].remolque_id !== null) ||
            (!this.form1[indexDet][indexDias].aplicaRemol &&
              this.form1[indexDet][indexDias].equipo_id &&
              this.form1[indexDet][indexDias].persona_id)
          ) {
            form.push(this.form1[indexDet][indexDias]);
          }
        } else if (
          this.det_solicitud.tipo_servicio == 2 &&
          this.form1[indexDet][indexDias].equipo_id &&
          this.form1[indexDet][indexDias].persona_id &&
          this.form1[indexDet][indexDias].aparejador_id
        ) {
          // Es Izaje
          form.push(this.form1[indexDet][indexDias]);
        }
      } else {
        for (let dias = 0; dias < this.diasPost; dias++) {
          if (!this.form1[indexDet][dias].id) {
            if (this.det_solicitud.tipo_servicio == 1) {
              // Es transporte
              if (
                (this.aplicaRemol &&
                  this.form1[indexDet][dias].equipo_id &&
                  this.form1[indexDet][dias].persona_id &&
                  this.form1[indexDet][dias].remolque_id !== null) ||
                (!this.aplicaRemol &&
                  this.form1[indexDet][dias].equipo_id &&
                  this.form1[indexDet][dias].persona_id)
              ) {
                form.push(this.form1[indexDet][dias]);
              }
            } else if (
              this.det_solicitud.tipo_servicio == 2 &&
              this.form1[indexDet][dias].equipo_id &&
              this.form1[indexDet][dias].persona_id &&
              this.form1[indexDet][dias].aparejador_id
            ) {
              // Es Izaje
              form.push(this.form1[indexDet][dias]);
            }
          }
        }
      }
      return form;
    },

    destroy(indexDet, indexDias) {
      this.$swal({
        title: "Está seguro de eliminar este detalle de la postulación?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then(async (result) => {
        if (result.value) {
          this.cargando = true;
          await axios
            .delete(
              "/api/cs/postulacionesInternas/" +
                this.form1[indexDet][indexDias].id
            )
            .then(() => {
              this.cargando = false;
              this.init();
              this.$swal({
                icon: "success",
                title:
                  "Se eliminó el detalle de la postulación exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    back() {
      return this.$router.replace("/Cs/PostulacionesInternos");
    },
  },

  mounted() {
    this.init();
  },
};
</script>
